import styled from 'styled-components';
import { mainColor } from 'constants/theme';

export const FormContainer = styled.div`
  width: 100%;
  select {
    appearance: none;
    background: #fff;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em),
      calc(100% - 15px) calc(1em), calc(100% - 2.5em);
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  label {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin: 8px 0;
  }
  input,
  select {
    display: block;
    box-sizing: border-box;
    margin: 8px 0;
    padding: 8px;
    width: 100%;
    appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  button {
    border: 0;
    background: ${mainColor};
    background: ${props => props.theme.linkColor};
    padding: 8px 16px;
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: bold;
  }
`;
